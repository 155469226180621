import { ExternalImport, IExternalImportOptions } from "client/services/Import/ExternalImport";
import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { useEffect } from "react";

const externalImports: ExternalImport[] = [];

export const useExternalImport = (options: IExternalImportOptions) => {
    const handleExternalImport = async (task: Task, metaData: any) => {
        const externalImport = new ExternalImport(task, options, metaData);
        await externalImport.startTask();
        externalImports.push();
    };

    useEffect(() => {
        return () => {
            externalImports.forEach((externalImport) => externalImport.destroy());
        };
    }, []);

    return {
        handleExternalImport,
    };
};
