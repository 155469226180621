import {
    addToast as addToastAction,
    removeToast as removeToastAction,
    removeBatch as removeBatchAction,
} from "store/features/toasts/actions";
import { useAppDispatch } from "store/store";
import { getRandomId } from "client/utils/getRandomId";
import { Toast } from "store/features/toasts/types";

export const useToast = () => {
    const dispatch = useAppDispatch();

    const addToast = (toast: Omit<Toast, "id">) => {
        const id = getRandomId();

        dispatch(
            addToastAction({
                toast: {
                    id,
                    ...toast,
                },
            }),
        );

        if (toast.time) {
            setTimeout(() => {
                dispatch(removeToastAction({ id }));
            }, toast.time);
        }

        return id;
    };

    const removeToast = (id: string) => dispatch(removeToastAction({ id }));

    const removeBatch = (batch: string) => dispatch(removeBatchAction({ batch }));

    return { toast: addToast, removeToast, removeBatch };
};
