import { CloseIcon } from "components/common/Icons/CloseIcon";
import { Modal } from "components/common/Modal/Modal";
import { Translate } from "components/common/Translate/Translate";

export interface GoogleDriveAccessErrorModalProps {
    isOpen: boolean;
    close: () => void;
    handleRetry: () => void;
}

export const GoogleDriveAccessErrorModal = ({ isOpen, close, handleRetry }: GoogleDriveAccessErrorModalProps) => {
    return (
        <Modal isOpen={isOpen} close={close}>
            <div
                className="relative bg-white dark:bg-darkSurface-300 rounded-md p-4 lg:p-8 text-black flex flex-col my-auto gap-4"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    className="absolute right-4 top-4 cursor-pointer focus:outline focus:outline-2 focus:outline-irBlue-600"
                    onClick={close}
                >
                    <CloseIcon strokeWidth={4} />
                </button>
                <div className="text-red-500 text-xl font-semibold">
                    <Translate keyName="common:google-drive-access-error-title" />
                </div>
                <div className="text-black dark:text-white">
                    <Translate
                        keyName="common:google-drive-access-error-description"
                        linkRenderer={(label, key) => {
                            if (key === "link:regrant") {
                                return (
                                    <button
                                        className="cursor-pointer text-irBlue-400 dark:text-darkPrimary-500"
                                        onClick={handleRetry}
                                        key={key}
                                    >
                                        {label}
                                    </button>
                                );
                            }
                            return (
                                <a
                                    href="/images/fix-google-permission.png"
                                    className="cursor-pointer text-irBlue-600 dark:text-darkPrimary-500"
                                    target={"_blank"}
                                    key={key}
                                >
                                    {label}
                                </a>
                            );
                        }}
                    />
                </div>
                <div className="mt-8 ml-auto flex items-center gap-4">
                    <button
                        onClick={close}
                        className="py-2 px-8 bg-red-500 rounded-xl hover:bg-red-700 text-white capitalize"
                    >
                        <Translate keyName="common:cancel" />
                    </button>
                    <button
                        onClick={handleRetry}
                        className="py-2 px-16 bg-irBlue-200 dark:bg-darkPrimary-500 dark:text-black rounded-xl hover:bg-irBlue-600 text-white capitalize"
                    >
                        <Translate keyName="common:retry" />
                    </button>
                </div>
            </div>
        </Modal>
    );
};
