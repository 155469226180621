import { DEFAULT_TRUNCATE_LENGTH, truncate } from "./truncate";

export const truncateImageTitle = (text: string, length: number = DEFAULT_TRUNCATE_LENGTH) => {
    if (text.length <= length) return text;

    const ext = text.split(".")[text.split(".").length - 1];
    const titleWithoutExt = text.substring(0, text.length - ext?.length ?? 0 - 1);

    return `${truncate(titleWithoutExt, length)}.${ext}`;
};
