import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { getFCUrl } from "client/utils/getFCUrl";
import { get } from "../Backend/get";
import { TaskService } from "../Task/TaskService";
import { TaskSocket } from "../Task/TaskSocket";

export interface IExternalImportOptions {
    onStart: (task: Task, metaData: any) => void;
    onComplete: (task: Task, metaData: any) => void;
    onFailure: (task: Task, metaData: any) => void;
    onDisconnect?: (task: Task, metaData: any) => void;
    onReconnect?: (task: Task, metaData: any) => void;
    onOfflineLimitReach?: (task: Task, metaData: any) => void;
}

export class ExternalImport {
    private task: Task;
    private options: IExternalImportOptions;
    private periodicStatusCheckInterval: NodeJS.Timer | null = null;
    private metaData: any;
    private isComplete = false;

    constructor(task: Task, options: IExternalImportOptions, metaData: any) {
        this.task = task;
        this.options = options;
        this.metaData = metaData;
    }

    public async startTask() {
        this.init();
        const taskSocket = new TaskService(this.task, {
            handleDisconnect: this.handleDisconnect,
            handleReconnect: this.handleReconnect,
            handleTaskComplete: this.handleComplete,
            handleTaskFail: this.handleTaskFail,
            onOfflineLimitReach: this.handleOnOfflineLimitReach,
        });
        await taskSocket.appendTask();
    }

    private handleDisconnect = () => {
        this.options.onDisconnect && this.options.onDisconnect(this.task, this.metaData);
    };

    private handleReconnect = () => {
        this.options.onReconnect && this.options.onReconnect(this.task, this.metaData);
    };

    private handleOnOfflineLimitReach = (task: Task) => {
        this.options.onOfflineLimitReach && this.options.onOfflineLimitReach(task, this.metaData);
    };

    private init() {
        this.options.onStart(this.task, this.metaData);
    }

    private initPeriodicStatusCheck() {
        this.periodicStatusCheckInterval = setInterval(async () => {
            this.checkStatus();
        }, 1000 * 60);
    }

    private removePeriodicStatusCheck() {
        this.periodicStatusCheckInterval && clearInterval(this.periodicStatusCheckInterval);
        this.periodicStatusCheckInterval = null;
    }

    private async checkStatus() {
        const task = await get<Task>(`/api/tasks/${this.task.id}`);

        if (task.error) return;

        if (task.result?.status === "completed") {
            this.handleComplete(task.result);
        }

        if (task.result?.status === "failed") {
            this.handleTaskFail(task.result);
        }
    }

    private handleComplete = (data: Task) => {
        if (this.isComplete) return;
        this.isComplete = true;
        this.options.onComplete(data, this.metaData);
        this.removePeriodicStatusCheck();
        this.destroy();
    };

    private handleTaskFail = (task: Task) => {
        this.options.onFailure(task, this.metaData);
        this.removePeriodicStatusCheck();
        this.destroy();
    };

    public cancel() {}

    public destroy() {}
}
