import { DeviceImport, DeviceImportOptions } from "client/services/Import/DeviceImport";
import { FileData } from "client/types/FileData";
import { Task } from "@freeconvert/freeconvert-node/dist/types";
import { useEffect } from "react";
import { FileState } from "store/features/imageUploader/types";

const deviceImports: DeviceImport[] = [];

export interface UseDeviceImportOptions extends DeviceImportOptions {
    onStart: (task: Task, metaData: any, progress?: FileState["progress"]) => void;
    shouldCancelOnUnmount?: boolean;
}

export const useDeviceImport = (options: UseDeviceImportOptions) => {
    const handleDeviceImport = async (file: FileData, task: Task, metaData: any) => {
        // 0 is passed as progress because progress is 0 at the beginning
        options.onStart(task, metaData, 0);

        const deviceImport = new DeviceImport(
            task,
            file,
            {
                ...options,
                onOfflineLimitReach: handleOfflineLimitReach,
            },
            metaData,
        );
        await deviceImport.startImport();

        deviceImports.push(deviceImport);
    };

    const handleDeviceCancel = (task: Task) => {
        const deviceImport = deviceImports.find((deviceImport) => deviceImport.task.id === task.id);
        deviceImport?.cancel();
    };

    const handleOfflineLimitReach = (task: Task, metaData: any) => {
        options.onOfflineLimitReach && options.onOfflineLimitReach(task, metaData);
        const deviceImport = deviceImports.find((deviceImport) => deviceImport.task.id === task.id);
        deviceImport?.cancel();
    };

    const handleCancelAllDeviceImports = () => {
        deviceImports.forEach((deviceImport) => deviceImport.cancel());
    };

    useEffect(() => {
        return () => {
            if (typeof options.shouldCancelOnUnmount !== "undefined" && options.shouldCancelOnUnmount === false) return;
            deviceImports.forEach((deviceImport) => deviceImport.cancel());
        };
    }, []);

    return {
        handleDeviceCancel,
        handleDeviceImport,
        handleCancelAllDeviceImports,
    };
};
