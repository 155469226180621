import { FileData } from "client/types/FileData";
import { formatBytes } from "./formatBytes";

export const getFileData = (file: File): FileData => {
    return {
        file: file,
        src: URL.createObjectURL(file),
        size: formatBytes(file.size),
    };
};
