import { sleep } from "client/utils/sleep";

export type HandleSingleFile<Image> = (image: Image, index: number) => Promise<void>;

export const BATCH_UPLOAD_NUMBER = 10;

export const useBatchImport = <Image>(
    onImportStart: (image: Image) => void,
    getImportingImages: () => Image[],
    getAddedImages: () => Image[],
    shouldSkipImport: boolean,
) => {
    const proceedNextImports = async () => {
        if (shouldSkipImport) return;
        // this delay is added so that this gets the latest updates
        // it's not about the amount of time, it just needs a delay imo
        await sleep(1000);

        const importingImages = getImportingImages();
        const addedImages = getAddedImages();

        if (!shouldProceedToNextImport(addedImages.length, importingImages.length)) return;

        addedImages.slice(0, BATCH_UPLOAD_NUMBER - importingImages.length).forEach((image) => {
            onImportStart(image);
        });
    };

    const shouldProceedToNextImport = (addedImagesCount: number, importingImagesCount: number) =>
        !(addedImagesCount === 0 || importingImagesCount >= BATCH_UPLOAD_NUMBER);

    const hasExceededConcurrentImportingLimit = (index: number, importingImagesCount: number): boolean =>
        !(index + importingImagesCount < BATCH_UPLOAD_NUMBER);

    const handleBatchedSingleFile: HandleSingleFile<Image> = async (image, index) => {
        if (shouldSkipImport || hasExceededConcurrentImportingLimit(index, getImportingImages().length)) return;
        await sleep(100);
        onImportStart(image);
    };

    return {
        handleBatchedSingleFile,
        proceedNextImports,
    };
};
